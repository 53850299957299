<template>
  <div>
    <h1>Settings</h1>

    <div class="w-full lg:w-2/5 bg-white shadow-md leading-loose p-6">
      <div>
        <label class="block font-bold text-gray-600 pb-3">
          Project Name:
        </label>
        <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" v-model="project_name" type="text">
      </div>
      <div>
        <label class="block font-bold text-gray-600 py-3">
          Account Name:
        </label>
        <select v-model="account_id" class="w-full h-9 px-3 py-1 text-gray-700 bg-gray-200 rounded">
        <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }} ({{ account.username }})</option>
        </select>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="justify-self-start">
          <button class="pt-1 pr-3 pb-1 pl-3 text-white tracking-wider bg-gray-500 rounded" @click="updateProject">
            Save changes
          </button>
        </div>
        <div class="justify-self-end">
          <button class="pt-1 pr-3 pb-1 pl-3 text-white font-light tracking-wider bg-red-500 rounded" @click="deleteProject">
            Delete project
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data() {
    return {
      project_name: null,
      account_id: null,
      accounts: []
    }
  },
  created () {
    // Get project settings
    axios.get(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}`)
      .then(response => {
        this.project_name = response.data.name;
        this.account_id = response.data.account_id;
      })
      .catch(error => {
        console.log(error)
      });
    
    // Get accounts
    axios.get(`${process.env.VUE_APP_API_URL}/accounts?purpose=messages`)
      .then(response => {
        this.accounts = response.data;
      });
  },
  methods: {
    updateProject(evt) {
      evt.preventDefault();

      axios.put(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}`, {
          name: this.project_name,
          account_id: this.account_id,
        }).catch(error => {
          console.log(error)
        })
    },
    deleteProject(evt) {
      evt.preventDefault();
      
      if (confirm('Are you sure you want to delete this project?'))
      {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/projects/${this.projectId}`)
          .then(() => {
            this.$router.push(`/`)
          })
      }
    }
  }
}
</script>